<template>

    <Head>
        <title>{{ page.meta_title }}</title>
        <meta  name="description" v-if="page.meta_description" :content="page.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>


    <section class="text-gray-600 body-font section-text">
        <div class="bg-white  dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 page-ckeditor pb-10">
                    <div class="pt-6 pb-3">

                        <nav class="flex" aria-label="Breadcrumb">
                            <button @click.passive="back" v-if="backExists()" class=" text-sm font-medium text-gray-700 hover:text-gray-900 border-gray-300 border rounded p-1.5 pl-2 pr-3 mr-2 float-left">
                                <span class="sr-only">Terug naar overzicht</span>
                                <span class="whitespace-nowrap text-xs sm:text-sm  dark:text-gray-200 dark:hover:text-white">

                             <CustomSvg :name="'chevron-left'" color="blue" customClass="w-3 mt-0.5 float-left mr-2 dark:fill-gray-300"></CustomSvg>
                                    Terug
                                        </span>
                            </button>

                            <ol class="inline-flex items-center space-x-1 md:space-x-3 ml-3">
                                <li class="inline-flex items-center">
                                    <jet-nav-link href="/"
                                                  class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                                        </svg>
                                    </jet-nav-link>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-auto h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clip-rule="evenodd"></path>
                                        </svg>
                                        <jet-nav-link :href="'/'+page.url"
                                                      class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                                            {{page.name }}
                                        </jet-nav-link>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <div class="clear-both"></div>

                        <h2 class="text-2xl font-bold text-gray-900 pt-6">{{ page.name }}</h2>
                        <p>{{ page.description }}</p>
                        <div class="
                        ms:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-12 sm:gap-y-12
                        lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-12 lg:gap-y-12">

                            <div v-for="callout in page.news" :key="callout.name" class="group relative pt-10">
                                <jet-nav-link :href="'/'+page.url+'/'+callout.url" class="!no-underline">

                                    <div class="relative h-64 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75
                        sm:aspect-w-2 sm:aspect-h-1 sm:h-64
                        lg:aspect-w-2 lg:aspect-h-1">
                                        <img :src="'https://cdn.bewinkel.com/600x300a/'+callout.image" :alt="callout.title" class="w-full h-auto  object-center"/>
                                    </div>
                                    <p class="mt-6 text-sm text-gray-500  dark:text-gray-300">
                                        <span class="absolute inset-0"/>
                                        {{ callout.description.substring(0, 50) }}<span v-if="callout.description.length > 50">...</span>
                                    </p>
                                    <h3 class="text-base font-semibold text-gray-900 mt-0 dark:text-white">{{ callout.title }}</h3>
                                </jet-nav-link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </section>

    <Footer :menu_items="menu_items" :page="page"  :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>
import JetNavLink from '@/Jetstream/NavLink.vue'
import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import { Head } from '@inertiajs/vue3'
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';

export default {

    components: {
        Header,
        Footer,
        JetNavLink,
        Head,
        CustomSvg
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {}
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        },
        backExists() {
            if (typeof window !== "undefined") {
                return window.history.length > 1;
            }
            return false;
        },
        back() {
            if (typeof window !== "undefined") {
                window.history.back();
            }
        },
    }
}
</script>

